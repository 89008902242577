<template>
    <Head title="Novo Estudante" />
    <PainelLayout>
        <div>
            <div
                v-if="!$page.props.flash.success"
                class="content__section_1"
                :style="`background-image: url('./../../bg_section_1.jpg')`"
            >
                <header-gratuito></header-gratuito>
                <div class="container">
                    <div class="row row-mobile ma-0">
                        <div
                            class="col text__section"
                            cols="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                            order="2"
                            order-sm="2"
                            order-md="2"
                            order-lg="1"
                            order-xl="1"
                        >
                            <div class="banner">
                                <div class="banner_txt">
                                    <form @submit.prevent="submit">
                                        <h3 class="titulo_banner">
                                            Projeto Social Sua Meia
                                            e ABE
                                        </h3>
                                        <h4 class="subtitulo_banner">
                                            Nós da Sua Meia e ABE
                                            acreditamos no poder da educação e
                                            cultura, por isso disponibilizamos
                                            Carteiras de Estudante Digital
                                            gratuitamente para alunos de escolas
                                            públicas do 1º ao 3º ano do ensino
                                            médio.
                                        </h4>
                                        <p class="observacao_banner">
                                            Peça a sua carteirinha pelo
                                            formulário abaixo.
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col image__section"
                            cols="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                            order="1"
                            order-sm="1"
                            order-md="1"
                            order-lg="2"
                            order-xl="2"
                        >
                            <div class="pt-5 pl-4 img_young">
                                <img
                                    :src="`./../../young-afro-brazilian-woman.png`"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="py-12">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="overflow-hidden shadow-sm sm:rounded-lg">
                    <div class="p-6 text-gray-900">
                        <form @submit.prevent="submit">
                            <NewEstudentForm
                                :form="form"
                                :instituicao="instituicao"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </PainelLayout>
</template>

<script setup>
import HeaderGratuito from "@/Components/gratuito/HeaderGratuito.vue";
import PainelLayout from "@/Layouts/PainelLayout.vue";
import { useForm, Head } from "@inertiajs/inertia-vue3";
import NewEstudentForm from "./Form.vue";

const props = defineProps({
    instituicao: {
        type: Object,
    },
});

const form = useForm({
    password: "",
    name: "",
    social_name: "",
    email: "",
    phone: "",
    document: "",
    address_zipcode: "",
    address_street: "",
    address_number: "",
    address_complement: "",
    address_district: "",
    address_city: "",
    address_state: "",
    educational_level_id: 2,
    educational_instituition_id: "",
    educational_instituition_name: "",
    grade: "",
    register: "",
    course: "",
    photo: "",
    document_photo: "",
    real_id: "",
    issuer: "",
    emission_date: "",
    birth_date: "",
    gender: "",
    mother_name: "",
    nationality: "",
    file_selfie: "",
    file_document: "",
    file_document_back: "",
    file_document_selfie: "",
    file_certificate: "",
    h_captcha_response: "",
});
const submit = () => {
    // console.log('form');
    // console.log(form);
    form.post(route("newestudents.store"));
};
</script>

<style scoped>
.titulo_banner {
    font-size: 1.7rem;
    line-height: 2rem;
}
.observacao_banner {
    font-size: 1.5rem;
}
.subtitulo_banner {
    font-size: 1.3rem;
    line-height: 1.45rem;

}
</style>
